import { render, staticRenderFns } from "./ProdutosLista.vue?vue&type=template&id=350602ce&scoped=true"
import script from "./ProdutosLista.vue?vue&type=script&lang=js"
export * from "./ProdutosLista.vue?vue&type=script&lang=js"
import style0 from "./ProdutosLista.vue?vue&type=style&index=0&id=350602ce&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/.pnpm/vue-loader@15.11.1_css-loader@6.11.0_vue-template-compiler@2.7.16_webpack@5.92.0/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "350602ce",
  null
  
)

export default component.exports